<template>
    <transition name="fading">
        <Loading v-if="isLoading"/>
    </transition>

    <Modal
      :isVisible="modal.isSubmitting"
      promptText="Are you sure u want to edit this?"
      yesText="Confirm"
      noText="Cancel"
      @yes="submit_edit()"
      @no="close_modal()"
    /> 

    <div class="container platform-size">
        <div class="card hidden mt-4">
                <div class="left-item">
                    <div class="btn green-btn px-3 desktop-view" @click="$router.push('/users')">
                        <i class="fa fa-chevron-left"></i> Back
                    </div>
                    <div class="btn green-btn px-3 mobile-view"  @click="$router.push('/users')">
                        <i class="fa fa-chevron-left"></i>
                    </div>
                </div>

            <div class="text-center">
                <h2>Users Edit</h2>
                <h5 class="mb-1">{{ user_data.full_name }}</h5>
            </div>
        </div>
    </div>

    <div class="container platform-size">
        <div class="card round-card mb-1">
            <div class="card-body mx-3">

                <div class="mb-3 mx-3">
                    <h6 class="form-label mx-2 category">Full Name</h6>
                    <input type="text" class="form-control mx-2" :disabled="!isEditing" v-model="user_data.full_name">
                </div>

                <div class="mb-3 mx-3">
                    <h6 class="form-label mx-2 category">Profession</h6>
                    <div>
                        <input type="text" class="form-control mx-2" :disabled="!isEditing"  v-model="user_data.profession">
                    </div>
                </div>

                <div class="mb-3 mx-3">
                    <h6 class="form-label mx-2 category">Specialization</h6>
                    <div>
                        <input type="text" class="form-control mx-2" :disabled="!isEditing"  v-model="user_data.specialization">
                    </div>
                </div>

                <div class="mb-3 mx-3">
                    <h6 class="form-label mx-2 category">Clinic Name</h6>
                    <div>
                        <input type="text" class="form-control mx-2" :disabled="!isEditing"  v-model="user_data.clinic_name">
                    </div>
                </div>

                <div class="mb-3 mx-3">
                    <h6 class="form-label mx-2 category">Locations</h6>
                            
                    <div class="row mx-1">
                        <div class="my-1 clinic_list" v-for="(clinic_id, ind) in user_data.locations" :key="ind">
                            <span class="mx-2">{{ clinicsJson[clinic_id]  }}</span>
                            <span class="ms-auto mx-1" v-if="isEditing"><i @click="remove_clinic(ind)" class="fa fa-times-circle-o" style="color:red"></i></span>
                        </div>
                    </div>

                    <h6 class="form-label mx-4 my-2" v-if="isEditing">Select locations to add:</h6>

                    <select class="form-select mx-2 mt-2" v-model="selectedClinic" v-if="isEditing">
                        <option value=""></option>
                        <option v-for="(data, key) in clinicsSelection" :key="key" :value="data.uuid">
                          {{ data.name }}
                        </option>
                    </select>

                    
                </div>

                <div class="mb-3 mx-3">
                    <h6 class="form-label mx-2 category">Contact Number</h6>
                    <div>
                        <input type="text" class="form-control mx-2" :disabled="!isEditing"  v-model="user_data.contact_number">
                    </div>
                </div>

            </div>

            <div class="card no-border mx-4">
                <div class="d-flex mx-3 mb-3">
                    <div class="ms-auto">
                        <div class="btn green-btn text-white mx-1" v-if="!isEditing" @click="isEditing = true">
                            <i class="fa fa-pencil-square-o"></i> Edit
                        </div>
                        <div class="btn red-btn text-white mx-1" v-if="isEditing" @click="isEditing = false">
                            <i class="fa fa-ban"></i> Cancel
                        </div>
                        <div class="btn green-btn text-white mx-1" v-if="isEditing" @click="modal.isSubmitting = true; isEditing = false">
                            <i class="fa fa-floppy-o"></i> Submit 
                        </div>
                    </div>
                </div>
           </div>


        </div>
    </div>



</template>

<script>
import Loading from "@/components/Loading.vue";
import Modal from "@/components/Modal.vue";
import { getAllClinics } from "@/services/clinicServices";
import { editUser, getCurrentUser } from "@/services/userServices";

export default{
components: {
    Loading,
    Modal,
},

created(){
    this.user_id = this.$route.params.id;
    this.get_data(this.user_id);
    this.get_clinics();
    console.warn("Created DOM!");
},

watch: {
    // Watch for changes to selectedClinic and add the clinic to locations automatically
    selectedClinic(newClinic) {
      if (newClinic && !this.user_data.locations.includes(newClinic)) {
        this.user_data.locations.push(newClinic);

        console.log(this.user_data.locations);
      }
    }
  },

data() {return {   
    user_id: '',
    raw_user_data: [],
    user_data: {
        
    },
    selectedClinic: "",
    clinicsJson: [],
    clinicsSelection: [],
    isLoading: false,
    modal: {
        isSubmitting: false,
    },
    isEditing: false
}

},
computed: {
},
methods: {
    async get_data(uuid){
        this.isLoading = true
        const result = await getCurrentUser(uuid);

        if(result.user_info){
            this.isLoading = false;
            this.raw_user_data = result.user_info;
        }

        this.convert_data(this.raw_user_data);
    },

    async get_clinics(){
        const result = await getAllClinics();

        if(result.data){
            for(let i=0; i<result.data.length; i++){
                this.clinicsJson[result.data[i].LocationUuid] = result.data[i].ClinicName;

                let temp = {
                    name: result.data[i].ClinicName,
                    uuid: result.data[i].LocationUuid,
                }

                this.clinicsSelection.push(temp)
            }
        }

        console.log(this.clinicsJson, this.clinicsSelection);
    },

    convert_data(input_data){
        this.user_data = {
            full_name: input_data.FullName,
            profession: input_data.Profession,
            specialization: input_data.Specialization,
            clinic_name: input_data.ClinicName,
            contact_number: input_data.ContactNumber,
            locations: Object.values(input_data.Locations),
            additional_info: input_data.AdditionalInfo
        },

        console.log(this.user_data);
    },

    remove_clinic(key){
        this.user_data.locations.splice(key, 1);  // This will remove the item at the index and shift the rest
        console.log(this.user_data.locations);
    },

    close_modal(){
        this.modal.isSubmitting = false;
        this.convert_data(this.raw_user_data);
    },

    async submit_edit(){
        this.isLoading = true;
        this.isEditing = false;
        this.modal.isSubmitting = false;

        console.log(this.user_data, this.user_id);
        const result = await editUser(this.user_data, this.user_id);
        if(result){
            this.isLoading = false;
        }

        this.get_data(this.user_id);
    }
}
}
</script>

<style scoped>

.header {
display: flex;
justify-content: space-between;
align-items: center;
position: relative;
padding: 0 1rem;
}

.title {
text-align: center;
flex: 1;
}

.pagination-wrapper {
overflow-x: auto;
white-space: nowrap;
}

.pagination-wrapper .pagination {
display: inline-flex;
flex-wrap: nowrap;
}

.platform-size{
width:70% !important;
padding:10px !important;
}

/*show desktop only on normal width*/
.desktop-view{
display: block;
}
.mobile-view{
display: none;
}

@media (max-width: 785px) {

/*show mobile only*/
.desktop-view{
display: none;
}
.mobile-view{
display: flex;
}

.platform-size{
width:100% !important;
padding:8px !important;
}

.header {
flex-direction: column;
align-items: flex-start;
}

.title {
order: 1;
margin: 0.5rem 0;
flex: none;
align-items: center;
}

.logout-button {
order: 2;
}

}

.overlay-content {
position: relative;
width: 100%;
max-width: 500px;
padding: 20px;
}

.overlay-close {
position: absolute;
top: 10px;
right: 10px;
color: white;
font-size: 2rem;
}

.overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
z-index: 1000;
cursor:auto;
}

.card-close {
position: absolute;
top: 10px;
right: 10px;
color: black;
font-size: 1.5rem;
cursor: pointer;
}

.category{
border-radius:8px; 
border-style: solid; 
border-width: 1px;
width:auto;
max-width: 150px;
padding:4px;
margin:4px;
text-align:center;
}

.clinic_list{
border-radius:8px; 
background-color:#cefffd;
border-width: 1px;
width:auto;
max-width: 600px;
padding:5px;
margin:4px;
text-align:center;
}

.border-list{
border-radius:8px; 
border-style: solid; 
border-width: 1px;
padding:4px;
margin:4px;
}

.card-shadow {
overflow: hidden;
box-shadow: 0 0 10px rgba(0, 62, 65, 0.5);
}

.row .card .card-body{
display: inline-flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
}

.round-card{
padding:5px; 
border-radius:15px;
}

.card.hidden{
    border: none;
    background-color: rgba(255, 255, 255, 0)
}

.card-body .card-title{
margin-top: 0;
margin-bottom: 0;
font-weight: 700;
}

div{
font-family: "Montserrat", sans-serif;
font-optical-sizing: auto;
}

.green-btn{
background-color: #0FBAB2;
color: white;
padding:8px;
}

.red-btn{
background-color: #dc362e !important;
color: white;
padding:8px;
}

.white-btn{
background-color: #ffffff;
color: black;
padding:4px;
}

.disabled-input{
    background-color: #f9fafe !important;
    border-radius:8px;
}

.bg-color{
background-color: #0FBAB2
}

.spinner {
animation: spin-animation 1s infinite;
display: inline-block;
}

@keyframes spin-animation {
0% {
transform: rotate(0deg);
}
100% {
transform: rotate(360deg);
}
}

#synonyms_table {
display: flex;
justify-content: center;
align-items: center;
height: 100%;
width: 100%;
}

#synonyms_table table {
width: 80%;
}

.fading-enter-active,
.fading-leave-active {
transition: opacity 0.3s
}

.fading-enter-from,
.fading-leave-to {
opacity: 0
}

.slide-move,
.slide-enter-active,
.slide-leave-active{
transition: all 0.5s ease;
}
.slide-enter-from,
.slide-leave-to{
opacity: 0;
transform: translateX(-50px);
}
.slide-leave-active{
position:absolute;
}

.fadechange-move,
.fadechange-enter-active,
.fadechange-leave-active{
transition: all 0.5s ease;
}
.fadechange-enter-from,
.fadechange-leave-to{
opacity: 0;
transform: translateY(-50px);
}
.fadechange-leave-active{
position:absolute;
}


.option-title h4 {
margin-top: 0;
font-size: 1.5em;
color: #333;
}

.option-title p {
margin: 8px 0;
font-size: 1em;
color: #666;
}

.card-disclaimer {
margin-top: -5px;
margin-bottom: 5px;
padding: 4px;
background-color: #fff0d3;
border-radius: 4px;
color:red;
font-size: 14px;
}

.card-disclaimer p {
margin: 0;
font-size: 0.875em;
color: #856404;
}

.absolute-right {
position: absolute;
top: 10px;
right: 10px;
}

.right-item {
position: absolute;
top: 10px;
right: 0px;
color: black;
cursor: pointer;
white-space: nowrap;
}

.absolute-left {
position: absolute;
top: 10px;
left: 10px;
}

.left-item {
position: absolute;
top: 10px;
left: 0px;
color: black;
cursor: pointer;
white-space: nowrap;
}

.no-border{
    border: none;
}

.bold-txt{
    font-weight: 550;
}

.absolute-left {
position: absolute;
top: 10px;
left: 10px;
font-size: 2rem;
}
  
.left-item {
position: absolute;
top: 10px;
left: 0px;
color: black;
font-size: 1.5rem;
cursor: pointer;
white-space: nowrap;
}

.fav{
    background-color:#f9effa;
    color: black;
}


.fav:hover{
    background-color:#8139af;
    color: white;
}

.fav.active{
    background-color:#8139af;
    color: white;
}

.fav.active:hover{
    background-color:#f9effa;
    color: black;
}

</style>