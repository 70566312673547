import axios from 'axios';
import { useAuthStore } from '@/stores/authStore';

export const getAllClinics = async () => {
    const authStore = useAuthStore();

    try {
        const response = await axios.get('https://langserve-copilot-dp7nm56cca-as.a.run.app/nora_superadmin/clinics', {
        headers: {
            Authorization: `Bearer ${authStore.accessToken}`,
        },
        });
    
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return getAllClinics(); // Retry after refreshing
        }
        throw error;
    }
}

export const registerClinics= async (input_data) => {
    const authStore = useAuthStore();
    
    try {
      const response = await axios.post('https://langserve-copilot-dp7nm56cca-as.a.run.app/nora_superadmin/clinics', input_data, {
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      });
  
      return response.data; // Return response data upon successful registration
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return registerClinics(); // Retry after refreshing
        }
        throw error;
    }
  };

export const editClinics = async (input_data, id) => {
  const authStore = useAuthStore();
  try {
    const response = await axios.put(`https://langserve-copilot-dp7nm56cca-as.a.run.app/nora_superadmin/clinics/${ id }`, input_data, {
      headers: {
        Authorization: `Bearer ${authStore.accessToken}`,
      },
    });

    return response.data; // Return response data upon successful registration
  } catch (error) {
      if (error.response && error.response.status === 401) {
          // Token might be expired, attempt to refresh
          await authStore.refreshAccessToken();
          return editClinics(input_data, id); // Retry after refreshing
      }
      throw error;
  }
};

export const getCurrentClinic = async (location_uuid) => {
    const authStore = useAuthStore();
  
    try {
      const response = await axios.get(`https://langserve-copilot-dp7nm56cca-as.a.run.app/nora_superadmin/clinics/${location_uuid}`, {
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      });
  
      return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return getCurrentClinic(location_uuid); // Retry after refreshing
        }
        throw error;
    }
};