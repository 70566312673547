import { createRouter, createWebHistory } from 'vue-router';
import SymptomQues from '../pages/Symptom/SymptomQues.vue';
import SymptomEdit from '../pages/Symptom/SymptomEdit.vue';
import FlowQues from '../pages/Flow/FlowQues.vue';
import FlowEdit from '../pages/Flow/FlowEdit.vue';
import Login from '../pages/Login.vue';
import ClinicsList from '../pages/Clinics/Clinics.vue';
import ClinicsEdit from '../pages/Clinics/ClinicsEdit.vue';
import UsersList from '../pages/Users/Users.vue';
import UsersEdit from '../pages/Users/UsersEdit.vue';
import { useAuthStore } from '@/stores/authStore';

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    name: 'symptom-ques',
    component: SymptomQues,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/question/edit/:id',
    name: 'symptom-edit',
    component: SymptomEdit,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/flow',
    name: 'flow-ques',
    component: FlowQues,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/flow/edit/:id',
    name: 'flow-edit',
    component: FlowEdit,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/clinics',
    name: 'clinics-list',
    component: ClinicsList,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/clinics/edit/:id',
    name: 'clinics-edit',
    component: ClinicsEdit,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/users',
    name: 'users-list',
    component: UsersList,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/users/edit/:id',
    name: 'users-edit',
    component: UsersEdit,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  authStore.loadTokensFromStorage(); // Load tokens from localStorage

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);
  const isAuthenticated = authStore.accessToken;

  if (requiresAuth) {
    if (!isAuthenticated) {
      next('/login');  // If no token is present, redirect immediately
      return;
    }

    try {
      await authStore.refreshAccessToken();
      await authStore.fetchUserRoles();  // Fetch user details to check role

      if (requiresAdmin && authStore.userRole !== 'admin') {
        authStore.logout();
        return next('/login');  // Redirect to login if user is not an admin
      }
    } catch (error) {
      console.error("Token refresh or user detail fetch failed, logging out.");
      authStore.logout();  // Log out and stop retrying
      return next('/login');
    }
  }

  if (to.name === 'login' && isAuthenticated) {
    next({ name: 'symptom-ques' });
  } else {
    next();
  }
});


export default router;
